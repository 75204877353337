.hide-carousel-buttons .carousel-control-prev,
.hide-carousel-buttons .carousel-control-next {
  display: none;
  
}
.carousel-slide{
  margin-top: 18 !important;
}
.carousel{
  margin-top: 10px !important;
  padding-inline: 5px ;
}