

.dataResult{
    margin-top: 5px;
    width: 300px;
    height: auto;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.35);
    overflow-y: auto;
    overflow: hidden;
    border-radius: 4px;
}
.dataResult::-webkit-scrollbar{
    display: none;
}
.dataResult .dataItem{
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    color: black;
}
.dataItem p {
    margin-left: 10px;
    height: 30px;
}
a{
    text-decoration: none;
}
.dataItem:hover{
background-color: rgb(222, 222, 222);
}
.dataItemMobile:hover{
    background-color: rgb(222, 222, 222);
    }
.dataResultMobile{
        background-color: aliceblue;
        position: absolute;
        top: 81%; /* Position the results below the input tag and button */
        left: 98;
        width: 215px;
        max-height: 100px; /* Set the maximum height to show 5 items */
        overflow-y: auto; /* Enable vertical scroll when necessary */
        scrollbar-width: thin; /* Set the width of the scrollbar to be thin */
        scrollbar-color: transparent transparent; /* Make the scrollbar transparent */
    
}

@media only screen and (max-width: 380px) {
    .dataResultMobile {
      width: 200px; /* Adjust the width for screens 380px and below */
    }
  }

  @media only screen and (max-width: 370px) {
    .dataResultMobile {
      width: 187px; /* Adjust the width for screens 370px and below */
    }
  }

  @media only screen and (max-width: 280px) {
    .dataResultMobile {
      width: 114px; /* Adjust the width for screens 280px and below */
    }
  }