/*Shimmer CSS */
.shimmer-container {
    display: flex;
    flex-wrap: wrap;
    gap: 25px;
    justify-content: center;
  }
  .shimmer-card {
    flex-basis: 250px;
    padding: 10px;
    margin-bottom: 10px;
    border-color: #d3d5df;
    box-shadow: 0 4px 7px 0 rgb(218 220 230 / 60%);
  }
  .shimmer-title {
    width: 60%;
    margin-top: 10px;
  }
  .shimmer-tags {
    width: 80%;
    margin-top: 4px;
  }
  .shimmer-details {
    width: 100%;
    margin-top: 18px;
  }
  .shimmer-container .shimmer-img {
    height: 144px;
    width: 230px;
  }
  
  /*Animation to Shimmer Component Cards */
  .stroke {
    height: 15px;
    background: #777;
  }
  .animate {
    animation: shimmer 3s;
    animation-iteration-count: infinite;
    background: linear-gradient(to right, #e6e6e6 5%, #cccccc 25%, #e6e6e6 35%);
    background-size: 1000px 100%;
  }
  @keyframes shimmer {
    from {
      background-position: -1000px 0;
    }
    to {
      background-position: 1000px 0;
    }
  }