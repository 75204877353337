.App {
  text-align: center;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/* body */
/* .chakra-container{

  -webkit-font-smoothing: antialiased;
  background-image: url(./assets/siteBg.jpg) !important;
  background-size: 100px;
  position: relative;
  
}
.chakra-container div{
  background-color: white !important;
} */

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.owl-carousel .owl-item img {
  width: auto !important;
}

/* .owl-carousel.owl-drag .owl-item {
  max-width: 320px !important;
} */

#hero-banner .owl-carousel.owl-drag .owl-item {
  display: flex;
  justify-content: center;
}

#hero-banner .owl-carousel .owl-item img {
  width: 100% !important;
}

#hero-banner .owl-theme .owl-dots {
  display: flex;
  justify-content: center;
}

#hero-banner .owl-theme .owl-dots .owl-dot {
  width: 48px;
  height: 48px;
}
#topAstrologer .owl-carousel.owl-drag .owl-item {
  display: flex;
  justify-content: center;
}
#topAstrologer .owl-carousel .owl-nav button.owl-prev {
  position: absolute;
  background: url("./assets/prev.png") !important;
  width: 45px;
  height: 45px;
  top: 45%;
  left: -2%;
  transform: translateY(-50%);
  transition: all 245ms ease-in-out;
  z-index: 1000;
}

#topAstrologer .owl-carousel .owl-nav button.owl-prev span {
  display: none;
}

#topAstrologer .owl-carousel .owl-nav button.owl-next {
  position: absolute;
  background: url("./assets/next.png") !important;
  width: 45px;
  height: 45px;
  top: 45%;
  right: -1.5%;
  transform: translateY(-50%);
  transition: all 245ms ease-in-out;
  z-index: 1000;
}

#topAstrologer .owl-carousel .owl-nav button.owl-next span {
  display: none;
}

#userTestimonial .owl-carousel.owl-drag .owl-item {
  display: flex;
  justify-content: center;
}

#userTestimonial .owl-carousel .owl-nav button.owl-next {
  position: absolute;
  background: url("./assets/next.png") !important;
  width: 45px;
  height: 45px;
  top: 45%;
  right: -2%;
  transform: translateY(-50%);
  transition: all 245ms ease-in-out;
  z-index: 1000;
}

#userTestimonial .owl-carousel .owl-nav button.owl-next span {
  display: none;
}

#userTestimonial .owl-carousel .owl-nav button.owl-prev {
  position: absolute;
  background: url("./assets/prev.png") !important;
  width: 45px;
  height: 45px;
  top: 45%;
  left: -2%;
  transform: translateY(-50%);
  transition: all 245ms ease-in-out;
  z-index: 1000;
}

#userTestimonial .owl-carousel .owl-nav button.owl-prev span {
  display: none;
}

#userReview .owl-carousel .owl-nav button.owl-prev {
  position: absolute;
  background: url("./assets/prev.png") !important;
  width: 51px;
  height: 51px;
  left: 43%;
  transition: all 245ms ease-in-out;
  z-index: 1000;
}

#userReview .owl-carousel .owl-nav button.owl-prev span {
  display: none;
}

#userReview .owl-theme {
  position: relative;
}

#userReview .owl-carousel .owl-nav button.owl-next {
  position: absolute;
  background: url("./assets/next.png") !important;
  width: 51px;
  height: 51px;
  right: 43%;
  transition: all 245ms ease-in-out;
  z-index: 1000;
}

#userReview .owl-carousel .owl-nav button.owl-next span {
  display: none;
}

#userReview .owl-carousel.owl-drag .owl-item {
  display: flex;
  justify-content: center;
}

/* @media only screen and (max-width: 600px) {
  #userTestimonial .owl-carousel.owl-drag .owl-item {
    display: unset !important ;
    justify-content: center;
  }
} */

#langFilter .bg {
  color: "white";
  background-color: "#DD7323";
}

/* .formDatepicker {
  width: fit-content !important;
  padding: 8px !important;
  border: 1px solid rgb(235, 233, 233) !important;
  border-radius: 6px;
} */
/* .formDatepicker :focus {
  
} */
.formDatepicker .react-datepicker__input-container input {
  padding: 7px !important;
  border: 1px solid rgb(235, 233, 233) !important;
  border-radius: 6px;
  width: 100%;
}
.formDatepicker .react-datepicker__input-container input:focus {
  /* border: none; */
  outline: none;
  border: 1px solid orange !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.navbarLinks {
  padding: 0.5rem 0.8rem;
}

.navbar {
  height: 220px;
}

.navbarLinks:hover {
  border-radius: 10px;
  background-color: #bc5d14;
  color: aliceblue !important;
}
a {
  text-decoration: none !important;
}

.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 300ms ease-out;
}

/* Phone Input Styling */
.PhoneInput {
  /* width: 90%; */
  background: antiquewhite;
  padding-left: 10px;
  border-radius: 5px;
}
.PhoneInput:focus {
  border: 2px solid darkorange;
}
.PhoneInputInput {
  padding-left: 5px;
  height: 35px;
  background: antiquewhite;
  border-radius: 0px 3px 3px 0px !important;
  border: 1px !important;
  font-size: 12px;
  color: #bc5d14;
  padding-left: 5px;
}
.PhoneInputInput:focus {
  outline: none;
}

.PhoneInput .PhoneInputCountryIcon .PhoneInputCountryIconImg {
  color: #bc5d14;
}
html,
body {
  /* touch-action: pan-x pan-y !important;
  -webkit-text-size-adjust: none;
  -ms-text-size-adjust: none;
  touch-action: manipulation; */
}
input,
input[type="text"],
textarea {
  font-size: 16px !important;
  /* -ms-touch-action: manipulation;
  touch-action: manipulation; */
}

.zsiq_custommain.siq_bR {
  margin-bottom: 70px;
}
.zsiq_floatmain.zsiq_theme11.siq_bR {
  margin-bottom: 81px;
}

/* Wallet Container height for making at Bottom*/
.wallet-container {
  /* height: 92vh; */
  padding-bottom: 5rem !important;
}

@media screen and (max-width: 767px) {
  .wallet-container {
    padding-bottom: 0 !important;
  }
}

/* live datepicker for live birthdate */
.liveFormDatepicker .react-datepicker__input-container input {
  padding: 7px !important;
  border: 1.5px solid #e53e3e !important;
  /* background-color: #FED7D7; */
  border-radius: 6px;
  width: 100%;
}
.liveFormDatepickerFill .react-datepicker__input-container input {
  padding: 7px !important;
  border: 2px solid transparent !important;
  background-color: #c6f6d5;
  border-radius: 6px;
  width: 100%;
}
.liveFormDatepicker .react-datepicker__input-container input:focus {
  /* border: none; */
  outline: none;
  border: 1.5px solid transparent !important;
}
.liveFormDatepickerFill .react-datepicker__input-container input:focus {
  /* border: none; */
  outline: none;
  border: 2px solid transparent !important;
}

#astro-card p {
  margin-bottom: 0 !important;
}

#unification p {
  margin-bottom: 0 !important;
}

#artigyan p {
  margin-bottom: 0 !important;
}
#artigyan-blog p {
  margin-bottom: 0 !important;
}

#text-reviews p {
  margin-bottom: 0 !important;
}

#user-testimonials p {
  margin-bottom: 0 !important;
}

#astro-profile p {
  margin-bottom: 0 !important;
}

#astro-profile-details p {
  margin-bottom: 0 !important;
}
@media (max-width: 767px) {
  #artigyan-blog p,
  #text-reviews p,
  #user-testimonials p,
  #astro-profile p,
  #astro-profile-details p {
    margin-bottom: unset !important;
  }
}

.ytp-shorts-mode.ytp-title-enable-channel-logo .ytp-title {
  min-height: 42px;
  visibility: hidden !important;
}

#top-navbar p {
  margin-bottom: 0 !important;
}

.draggable-freshchat {
  position: absolute !important;
  cursor: move;
}
#bottom-nav p {
  margin-bottom: 0 !important;
}
#alert-top p {
  margin-bottom: 0 !important;
}

.section-heading p {
  margin-bottom: 0 !important;
}

#live-call-modal p {
  margin-bottom: 0 !important;
}

.responsive-consult-card .owl-theme .owl-dots .owl-dot span {
  border: 2.5px solid #ff4c00;
  background-color: white;
  width: 16px;
  height: 16px;
}
.responsive-consult-card .owl-theme .owl-dots .owl-dot {
  width: 24px;
  height: 30px;
}
.responsive-consult-card .owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 0 !important;
}
.responsive-consult-card .owl-theme .owl-item img {
  width: 100 !important;
}
@media (max-width: 768px) {
  .responsive-consult-card .owl-theme .owl-dots .owl-dot span {
    border: 2.5px solid #ff4c00;
    background-color: white;
    width: 12px;
    height: 12px;
  }
}
.responsive-consult-card .owl-theme .owl-dots .owl-dot.active span {
  background-color: #ff4c00;
}

.css-feft4w {
  min-height: 100svh !important;
}

#callflowbirthtime::-webkit-calendar-picker-indicator{
  filter: invert(73%) sepia(23%) saturate(5863%) hue-rotate(358deg) brightness(100%) contrast(107%) ;
}

#callflowbirthdate::-webkit-calendar-picker-indicator{
  filter: invert(73%) sepia(23%) saturate(5863%) hue-rotate(358deg) brightness(100%) contrast(107%) ;
}