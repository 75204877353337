#review-checkbox .css-1ydjfm6 {
    display: none !important;
    font-size: 10px !important;
}
.css-6x44c9 {
    font-size: 10px !important;
    font-weight: 400 !important;
    margin: 0 !important;
}

.feedback {
    font-size: 12px !important;
}

@media screen and (min-width: 768px) {
    .css-6x44c9 {
        font-size: 14px !important;
        margin: 0 !important;
    }
    .feedback {
        font-size: 15px !important;
    }
}