#recommended-id .owl-carousel.owl-drag .owl-item {
  display: flex;
  justify-content: center;
}

#recommended-id .owl-carousel.owl-loaded{
height: 23rem;
padding-bottom: 0rem;
}




#recommended-id .owl-carousel .owl-nav button.owl-next {
  position: relative;
  left: 66.5rem;
  top: 0.55rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  border-radius: 100%;
  height: 40px; 
  /* top: 45%; */
  /* right: -2%; */
  /* right: 15%; */
  transform: translateY(-50%);
  transition: all 245ms ease-in-out;
  z-index: 1000;
  padding: 0 6px !important;
  padding-bottom: 10px !important;
  background-color: #FF4C00;
  color: aliceblue;
  font-size: 20px;
}
@media (max-width: 768px) {
  #recommended-id .owl-carousel .owl-nav button.owl-next {
    /* right: -2%; */
    /* height: 90px; */
  }
}
#recommended-id .owl-theme .owl-nav [class*='owl-']:hover {
  background: #FF4C00;
  color: whitesmoke;
  box-shadow: 0 0 5px 0 grey;
}
#recommended-id .owl-theme .owl-nav [class*='owl-']{
  background: #FF4C00;
  color: whitesmoke;
}
#recommended-id .owl-theme .owl-nav [class*='owl-']:active {
  background-color: #FFB798;
  color: whitesmoke;
  transition: background-color 0.5s;
}

#recommended-id .owl-carousel .owl-nav button.owl-next span {
  /* display: none; */
  font-size: 54px;
  position: relative;
  top: -9%;
  left: 5%;
}


#recommended-id .owl-carousel .owl-nav button.owl-prev {
  position: relative;
  left: 60.5rem;
  top: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  border-radius: 100%;
  height: 40px; 
  /* top: 45%; */
  /* left: -2%; */
  /* left: 15%; */
  transform: translateY(-50%);
  transition: all 245ms ease-in-out;
  z-index: 1000;
  padding: 0 6px !important;
  padding-bottom: 10px !important;
  background-color: #FF4C00;
  color: aliceblue;
  font-size: 20px;
}

#recommended-id .owl-carousel .owl-nav button.owl-prev span {
  /* display: none; */
  font-size: 54px;
  top: -9%;
  left: -2%;
  position: relative;
}
@media (max-width: 768px) {
  #recommended-id .owl-carousel .owl-nav button.owl-next span {
    font-size: 36px;
  }
  #recommended-id .owl-carousel .owl-nav button.owl-prev span{
    font-size: 36px;
  }
}
@media (max-width: 768px) {
  #recommended-id .owl-carousel .owl-nav button.owl-next {

    /* right: -2%; */
  }
}

@media (max-width: 768px) {
  #recommended-id .owl-carousel .owl-nav button.owl-prev {

    /* left: -2%; */
  }
}

@media (max-width: 425px) {
  #recommended-id .owl-carousel .owl-nav button.owl-next {
    /* right: -1%; */
    height: 70px;
  }
}

@media (max-width: 425px) {
  #recommended-id .owl-carousel .owl-nav button.owl-prev {
    height: 70px;
    /* left: -1%;   */
    }
   
    #recommended-id .owl-carousel .owl-stage {
      display: flex;
      /* width: 2876px !important; */
    }
    
}


@media (max-width: 425px) {
  #recommended-id .owl-carousel.owl-loaded{
      height: 16rem;
      padding-bottom: 0rem;
    }
}




@media (max-width: 425px) {
  #recommended-id .owl-carousel.owl-drag .owl-item {
      display: flex;
      justify-content: center;
      width: 207px !important;
      margin-right: -15px !important;
  }
}





element.style {
  width: 160.147px;
}
#recommended-id .owl-carousel.owl-drag .owl-item {
  display: flex;
  justify-content: center;

}