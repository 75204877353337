#text-reviews .owl-carousel.owl-drag .owl-item {
    display: flex;
    justify-content: center;
  }
  
  #text-reviews .owl-carousel .owl-nav button.owl-next {
    position: absolute;
    /* background: url("./assets/next.png") !important; */
    /* width: 45px; */
    height: 90px;
    top: 45%;
    /* right: -2%; */
    right: 15%;
    transform: translateY(-50%);
    transition: all 245ms ease-in-out;
    z-index: 1000;
    padding: 0 6px !important;
    padding-bottom: 10px !important;
  }
  @media (max-width: 768px) {
    #text-reviews .owl-carousel .owl-nav button.owl-next {
      right: -2%;
      /* height: 90px; */
    }
  }
  #text-reviews .owl-theme .owl-nav [class*='owl-']:hover {
    background: #FF4C00;
    color: whitesmoke;
    box-shadow: 0 0 5px 0 grey;
  }
  #text-reviews .owl-theme .owl-nav [class*='owl-']{
    background: #FF4C00;
    color: whitesmoke;
  }
  #text-reviews .owl-theme .owl-nav [class*='owl-']:active {
    background-color: #FFB798;
    color: whitesmoke;
    transition: background-color 0.5s;
  }
  
  #text-reviews .owl-carousel .owl-nav button.owl-next span {
    /* display: none; */
    font-size: 54px;
  }

  
  #text-reviews .owl-carousel .owl-nav button.owl-prev {
    position: absolute;
    /* background: url("./assets/prev.png") !important; */
    /* width: 45px;*/
    height: 90px; 
    top: 45%;
    /* left: -2%; */
    left: 15%;
    transform: translateY(-50%);
    transition: all 245ms ease-in-out;
    z-index: 1000;
    padding: 0 6px !important;
    padding-bottom: 10px !important;
  }

  #text-reviews .owl-carousel .owl-nav button.owl-prev span {
    /* display: none; */
    font-size: 54px;
  }
  @media (max-width: 768px) {
    #text-reviews .owl-carousel .owl-nav button.owl-next span {
      font-size: 36px;
    }
    #text-reviews .owl-carousel .owl-nav button.owl-prev span{
      font-size: 36px;
    }
  }
  @media (max-width: 768px) {
    #text-reviews .owl-carousel .owl-nav button.owl-next {
      
      right: -2%;
    }
  }
  
  @media (max-width: 768px) {
    #text-reviews .owl-carousel .owl-nav button.owl-prev {
      
      left: -2%;
    }
  }
  
  @media (max-width: 425px) {
    #text-reviews .owl-carousel .owl-nav button.owl-next {
      right: -1%;
      height: 70px;
    }
  }
  
  @media (max-width: 425px) {
    #text-reviews .owl-carousel .owl-nav button.owl-prev {
      height: 70px;
      left: -1%;    }
  }