:root {
  --primary: #6a59ff;
  --white: #ffffff;
  --bg: #f5f5f5;
}

.container {
  max-width: 124rem;
  padding: 4rem 1rem;
  margin: 0 auto;
}

#video-testimonials.swiper_container {
  width: 100%;
  height: 33rem;
  padding: 2rem 0;
  position: relative;
}

#video-testimonials .swiper-slide {
  width: 20rem;
  height: 25rem;
  position: relative;
}

@media (max-width: 500px) {
  #video-testimonials.swiper_container {
    height: 22rem;
    padding: 1rem 0;
  }
  #video-testimonials .swiper-slide {
    width: 10rem !important;
    height: 15rem !important;
  }
  #video-testimonials.swiper-slide img {
    width: 10rem !important;
    height: 15rem !important;
  }
}

#video-testimonials.swiper-slide img {
  width: 20rem;
  height: 25rem;
  border-radius: 2rem;
  object-fit: cover;
}

.swiper-slide-shadow-left,
.swiper-slide-shadow-right {
  display: none;
}

#video-testimonials .slider-controler {
  position: relative;
  bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

#video-testimonials .slider-controler .swiper-button-next {
  left: 55% !important;
  transform: translateX(-58%) !important;
}

@media (max-width: 990px) {
  #video-testimonials .slider-controler .swiper-button-next {
    left: 70% !important;
    transform: translateX(-70%) !important;
  }
}

@media (max-width: 450px) {
  #video-testimonials .slider-controler {
    bottom: 2rem !important;
  }
  #video-testimonials .slider-controler .swiper-button-next {
    left: 65% !important;
    transform: translateX(-80%) !important;
  }
}

@media (max-width: 990px) {
  #video-testimonials .slider-controler .swiper-button-prev {
    left: 30% !important;
    transform: translateX(-30%) !important;
  }
}

@media (max-width: 450px) {
  #video-testimonials .slider-controler .swiper-button-prev {
    left: 35% !important;
    transform: translateX(-20%) !important;
  }
}

#video-testimonials .slider-controler .slider-arrow {
  /* background: #BF6928; */
  /* width: 1rem; */
  /* height: 1rem; */
  border-radius: 50%;
  left: 45%;
  transform: translateX(-42%);
  filter: drop-shadow(0px 8px 24px rgba(18, 28, 53, 0.1));
}

#video-testimonials .slider-controler .slider-arrow ion-icon {
  /* font-size: 2rem; */
  color: #222224;
}
#video-testimonials .slider-controler .slider-arrow span {
  /* font-size: 2rem; */
  border: 1.5px solid #FF6F00;
  /* background-color: #FF6F00; */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  /* transform: rotate(130deg) */
  
}
#video-testimonials .slider-controler .slider-arrow span:active {
  /* font-size: 2rem; */
  border: 1.5px solid #FFB798;
}
#video-testimonials .slider-controler .slider-arrow .left-button {
  background-color: #FF6F00;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  position: relative;
  top: 8%;
}

#video-testimonials .slider-controler .slider-arrow .left-button:active {
  background-color: #FFB798;
 
}
#video-testimonials .slider-controler .slider-arrow .right-button {
  background-color: #FF6F00;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  position: relative;
  top: 8%;
  left: 15%;
}
#video-testimonials .slider-controler .slider-arrow .right-button:active {
  background-color: #FFB798;

}

#video-testimonials .slider-controler .slider-arrow::after {
  content: '';
}

#video-testimonials.swiper-pagination {
  position: relative;
  width: 15rem !important;
  bottom: 1rem;
}

#video-testimonials.swiper-pagination .swiper-pagination-bullet {
  filter: drop-shadow(0px 8px 24px rgba(18, 28, 53, 0.1));
}

#video-testimonials.swiper-pagination .swiper-pagination-bullet-active {
  background: var(--primary);
}

.react-player{
  border-radius: 12px !important; 
}