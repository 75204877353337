#liveastro .owl-carousel.owl-drag .owl-item {
    display: flex;
    justify-content: center;
  }

  #liveastro .owl-carousel.owl-loaded{
  height: 23rem;
  padding-bottom: 0rem;
}




  #liveastro .owl-carousel .owl-nav button.owl-next {
    position: relative;
    left: 68rem;
    top: 0.55rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    border-radius: 100%;
    height: 40px; 
    /* top: 45%; */
    /* right: -2%; */
    /* right: 15%; */
    transform: translateY(-50%);
    transition: all 245ms ease-in-out;
    z-index: 1000;
    padding: 0 6px !important;
    padding-bottom: 10px !important;
    background-color: #FF4C00;
    color: aliceblue;
    font-size: 20px;
  }
  @media (max-width: 768px) {
    #liveastro .owl-carousel .owl-nav button.owl-next {
      /* right: -2%; */
      /* height: 90px; */
    }
  }
  #liveastro .owl-theme .owl-nav [class*='owl-']:hover {
    background: #FF4C00;
    color: whitesmoke;
    box-shadow: 0 0 5px 0 grey;
  }
  #liveastro .owl-theme .owl-nav [class*='owl-']{
    background: #FF4C00;
    color: whitesmoke;
  }
  #liveastro .owl-theme .owl-nav [class*='owl-']:active {
    background-color: #FFB798;
    color: whitesmoke;
    transition: background-color 0.5s;
  }

  #liveastro .owl-carousel .owl-nav button.owl-next span {
    /* display: none; */
    font-size: 54px;
    position: relative;
    top: -9%;
    left: 5%;
  }


  #liveastro .owl-carousel .owl-nav button.owl-prev {
    position: relative;
    left: 62rem;
    top: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    border-radius: 100%;
    height: 40px; 
    /* top: 45%; */
    /* left: -2%; */
    /* left: 15%; */
    transform: translateY(-50%);
    transition: all 245ms ease-in-out;
    z-index: 1000;
    padding: 0 6px !important;
    padding-bottom: 10px !important;
    background-color: #FF4C00;
    color: aliceblue;
    font-size: 20px;
  }

  #liveastro .owl-carousel .owl-nav button.owl-prev span {
    /* display: none; */
    font-size: 54px;
    top: -9%;
    left: -2%;
    position: relative;
  }
  @media (max-width: 768px) {
    #liveastro .owl-carousel .owl-nav button.owl-next span {
      font-size: 36px;
    }
    #liveastro .owl-carousel .owl-nav button.owl-prev span{
      font-size: 36px;
    }
  }
  @media (max-width: 768px) {
    #liveastro .owl-carousel .owl-nav button.owl-next {

      /* right: -2%; */
    }
  }

  @media (max-width: 768px) {
    #liveastro .owl-carousel .owl-nav button.owl-prev {

      /* left: -2%; */
    }
  }

  @media (max-width: 425px) {
    #liveastro .owl-carousel .owl-nav button.owl-next {
      /* right: -1%; */
      height: 70px;
    }
  }

  @media (max-width: 425px) {
    #liveastro .owl-carousel .owl-nav button.owl-prev {
      height: 70px;
      /* left: -1%;   */
      }
  }











  
element.style {
    width: 160.147px;
}
#liveastro .owl-carousel.owl-drag .owl-item {
    display: flex;
    justify-content: center;
}