.astroProfile {
    background-image: url('../../assets/AstroProfile_BG.png');
    background-size: cover;
    background-position: center center;
    z-index: 1000 !important;
}

@media only screen and (max-width: 600px) {
    .astroProfile {
        background-image: unset !important;
    }
}

.chakra-modal__content-container {
    padding-right: 15px;
    padding-left: 15px;
}

.astroProfile p {
    margin-bottom: 0 !important;
}

.css-0 .css-r6z5ec{
    z-index: 10000;
}

/* loader animation */
.spinner {
    position: relative;
    width: 56px;
    height: 56px;
   }
   
   .spinner > div {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 13px solid rgba(181, 126, 16, 0.196);
    position: absolute;
    top: 0;
    left: 0;
    animation: spinner-g7vlvwmd 0.65s linear infinite;
    z-index: 0;
   }
   
   .spinner > div::before {
    content: '';
    height: 13px;
    width: 13px;
    border-radius: 50%;
    background: #ff8a23;
    position: absolute;
    top: 50%;
    animation: spinner-h1vps1md 1.3s infinite reverse steps(1);
    transform: translate(calc(2 * var(--translate-2)), calc(var(--translate) * 1%));
    z-index: 1;
   }
   
   .spinner > div:nth-of-type(1) {
    --translate: -50;
    --translate-2: calc(56px / 8);
   }
   
   .spinner > div:nth-of-type(1)::before {
    right: 0;
   }
   
   .spinner > div:nth-of-type(2) {
    --translate: 50;
    --translate-2: calc(-56px / 8);
    animation-delay: 0.65s;
    animation-direction: reverse;
    transform: translate(21.5px, 0);
   }
   
   .spinner > div:nth-of-type(2)::before {
    left: 0;
    transform: translate(calc(-56px / 4), -50%);
    animation-direction: normal;
   }
   
   @keyframes spinner-h1vps1md {
    0% {
     opacity: 0;
    }
   
    50% {
     opacity: 1;
    }
   }
   
   @keyframes spinner-g7vlvwmd {
    from {
     transform: translate(calc(var(--translate) * 1%), 0) translate(calc(var(--translate-2)), 0) rotate(0deg);
    }
   
    to {
     transform: translate(calc(var(--translate) * 1%), 0) translate(calc(var(--translate-2)), 0) rotate(360deg);
    }
   }