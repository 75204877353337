/* #n-hero-banner .owl-theme .owl-dots .owl-dot span {
  border: 2.5px solid #ff4c00;
  background-color: white;
  width: 16px;
  height: 16px;
}
#n-hero-banner .owl-theme .owl-dots .owl-dot {
  width: 35px;
  height: 48px;
}
@media (max-width: 768px) {
  #n-hero-banner .owl-theme .owl-dots .owl-dot span {
    border: 2.5px solid #ff4c00;
    background-color: white;
    width: 12px;
    height: 12px;
  }
}
#n-hero-banner .owl-theme .owl-dots .owl-dot.active span {
  background-color: #ff4c00;
}


 */



 #hero-banner .owl-theme .owl-dots .owl-dot span {
  border: 2.5px solid #ff4c00;
  background-color: white;
  width: 16px;
  height: 16px;
}
#hero-banner .owl-theme .owl-dots .owl-dot {
  width: 35px;
  height: 48px;
}
@media (max-width: 768px) {
  #hero-banner .owl-theme .owl-dots .owl-dot span {
    border: 2.5px solid #ff4c00;
    background-color: white;
    width: 12px;
    height: 12px;
  }
}
#hero-banner .owl-theme .owl-dots .owl-dot.active span {
  background-color: #ff4c00;
}