

.selected {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  color: #773300;
  background-color: #ffb984 !important;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.unselected {
  
  color: black;
  background-color: #ffebdb !important;
  /* filter: blur(2px); */
}

.blur {
  color: black;
  background-color: #ffebdb !important;
  filter: blur(2px);
}
.border_radius_desktop {
    border-radius: 0px !important;
    min-height: auto !important;
}

.price_total_card {
  box-shadow: none !important;
  border-top: 1px dotted !important;
  border-radius: unset !important;
}
.price_total_card .price_total_card_body {
  padding: 1.5rem 0.5rem !important;
}

.desktop_only {
  display: none;
}
@media screen and (min-width: 768px) {
  .desktop_only {
    display: block !important;
  }
  .price_total_card {
    display: none !important;
  }
  .border_radius_desktop {
    border-radius: 8px !important;
    min-height: 352px !important;
  }
}



/* #d-wrapper {
  background-color: #fff;
  } */
  /* #d-wrapper * {
  
  margin:0;
  padding:0;} */
  
  #d-wrapper	div.sep {
      min-height: 200px;
      padding: 32px 0;
  
    }
  
  #d-wrapper	.zig-zag-top:before{
      background: 
            linear-gradient(-45deg, #1ba1e2 16px, red 16px, blue 16px,  transparent 0), 
            linear-gradient(45deg, #1ba1e2 16px, transparent 0);
          background-position: left top;
          background-repeat: repeat-x;
          background-size: 22px 32px;
          content: " ";
          display: block;
  
          height: 32px;
      width: 100%;
  
      position: relative;
      bottom: 64px;
      left:0;
    }

    #d-wrapper	.zig-zag-top:before{
      background: 
            linear-gradient(-45deg, #1ba1e2 16px, red 16px, blue 16px,  transparent 0), 
            linear-gradient(45deg, #1ba1e2 16px, transparent 0);
          background-position: left top;
          background-repeat: repeat-x;
          background-size: 22px 32px;
          content: " ";
          display: block;
  
          height: 32px;
      width: 100%;
  
      position: relative;
      bottom: 64px;
      left:0;
    }

    /* #d-wrapper	.zig-zag-bottom p{
      padding-top: 14px;
    }
    #d-wrapper	.zig-zag-bottom-orange p{
      padding-top: 19px;
    } */

  #d-wrapper	.zig-zag-bottom{
      /* margin: 32px 0; */
      margin-top: 0;
      /* background: #FFD260; */
      display: flex;
    flex-direction: column;
    align-items: center;
    }

    #d-wrapper	.zig-zag-bottom-orange{
      /* margin: 32px 0; */
      margin-top: 0;
      /* background: #FFD260; */
      display: flex;
    flex-direction: column;
    align-items: center;
    }

  
  #d-wrapper	.zig-zag-bottom:after{
      background: 
            linear-gradient(-45deg, transparent 16px, #FFD260 0), 
            linear-gradient(45deg, transparent 16px, #FFD260  0);
          background-repeat: repeat-x;
      background-position: left bottom;
          background-size: 22px 32px;
          content: "";
          display: block;
      width: 100%;
      height: 22px;
  
         position: relative;
      top:12px;
      left:0px;
    }


  
  
  /* #d-wrapper	p:not(:last-child) {
      margin-bottom: 20px;
    } */
    #d-wrapper	.zig-zag-bottom-orange:after{
      background: 
            linear-gradient(-45deg, transparent 16px, #FF7C44 0), 
            linear-gradient(45deg, transparent 16px, #FF7C44  0);
          background-repeat: repeat-x;
      background-position: left bottom;
          background-size: 22px 32px;
          content: "";
          display: block;
  
      width: 100%;
      height: 32px;
  
         position: relative;
      top:12px;
      left:0px;
    }