#usersReview .owl-nav [class*="owl-"] {
  background: transparent;
  color: #ff4d00;
  text-decoration: none;
  font-size: 80px;
  margin-right: 30px;
}

#usersReview .owl-nav [class*="owl-"]:hover {
  background: transparent;
  color: #ff4d00;
  font-size: 80px;
  margin-right: 30px;
}

@media (max-width: 768px) {
  #usersReview .owl-nav {
    margin: auto;
    margin-left: 40px;
  }

  #usersReview .owl-nav [class*="owl-"] {
    padding: 0 !important;
  }
}
.owl-nav-arrow,
.owl-next,
.owl-prev {
  height: 0px;
  height: 0px;
  margin: 0px;
}

.css-4fj8nb {
  padding: 0px !important;
}
