#liveAstrologerBottom .owl-theme .owl-carousel.owl-loaded{
    height: 105px !important;
}

#liveAstrologerBottom .owl-carousel.owl-drag .owl-item {
    /* width: 384px !important; */
}

#liveAstrologerBottom .owl-theme .owl-dots .owl-dot span {
    border: 2.5px solid #ff4c00;
    background-color: white;
    width: 9px;
    height: 9px;
    position: relative;
    bottom: 3px;
  }
  #liveAstrologerBottom .owl-theme .owl-dots .owl-dot {
    width: 35px;
    height: 90px;
  }
  @media (max-width: 768px) {
    #liveAstrologerBottom .owl-theme .owl-dots .owl-dot span {
      border: 2.5px solid #ff4c00;
      background-color: white;
      width: 9px;
      height: 9px;
    }
  }
  #liveAstrologerBottom .owl-theme .owl-dots .owl-dot.active span {
    background-color: #ff4c00;
    position: relative;
    bottom: 3px;
  }
  