#free-live .owl-carousel.owl-drag .owl-item {
    display: flex;
    justify-content: center;
  }
  
  #free-live .owl-carousel .owl-nav button.owl-next {
    position: absolute;
    /* background: url("./assets/next.png") !important; */
    /* width: 45px; */
    /* height: 45px; */
    top: 45%;
    right: 0%;
    transform: translateY(-50%);
    transition: all 245ms ease-in-out;
    z-index: 1000;
    padding: 0 6px !important;
    padding-bottom: 10px !important;
    background-color: #CB5900;
  }
  
  #free-live .owl-theme .owl-nav [class*='owl-']:hover {
    background: #CB5900;
    color: whitesmoke;
    
  }
  
  #free-live .owl-carousel .owl-nav button.owl-next span {
    /* display: none; */
    font-size: 54px;
  }
  
  #free-live .owl-carousel .owl-nav button.owl-prev {
    position: absolute;
    /* background: url("./assets/prev.png") !important; */
    /* width: 45px;
    height: 45px; */
    top: 45%;
    left: 0%;
    transform: translateY(-50%);
    transition: all 245ms ease-in-out;
    z-index: 1000;
    padding: 0 6px !important;
    padding-bottom: 10px !important;
    background-color: #CB5900;
  }
  
  #free-live .owl-carousel .owl-nav button.owl-prev span {
    /* display: none; */
    font-size: 54px;
  }