#loveBanner .owl-dots .owl-dot.active span {
  background-color: #ff4d00 !important;
  margin-top: 10px !important;
}

#loveBanner .owl-dots .owl-dot span {
  background-color: #d9d9d9 !important;
  width: 12px;
  height: 12px;
  margin: 4px;
  margin-top: 50px !important;
}

/* .owl-theme .owl-nav.disabled + .owl-dots { */
/* margin-top: 100px !important;
} */
